import type { Wallet } from '../../types';
import { isAndroid } from '../../utils';
import RainbowLogo from '../logos/rainbowkit_icon.png';
import { getWalletConnectConnector } from './wallet-connect';
import type { WalletConnectOptions } from './wallet-connect-v2';

export const rainbow = (options: Omit<WalletConnectOptions, 'chains'>): Wallet => ({
    id: 'rainbow',
    name: 'Rainbow',
    iconUrl: RainbowLogo,
    downloadUrls: {
        qrCode: 'https://rainbow.download',
    },
    createConnector: (chains) => {
        const connector = getWalletConnectConnector('rainbow', options, chains);
        return {
            connector,
            mobile: {
                getAppLinkUri: () => {
                    return isAndroid() ? undefined : 'rainbow://wc';
                },
            },
        };
    },
});
