import { AcitveLoginType } from '@particle-network/analytics';

export function connectIdToLoginType(id: string): AcitveLoginType {
    if (id === 'metamask') {
        return AcitveLoginType.METAMASK;
    } else if (id === 'rainbow') {
        return AcitveLoginType.RAINBOW;
    } else if (id === 'particle_local_evm') {
        return AcitveLoginType.PRIVATE_KEY;
    } else if (id === 'phantom') {
        return AcitveLoginType.PHANTOM;
    } else if (id === 'particle') {
        return AcitveLoginType.PARTICLE;
    }

    return AcitveLoginType.OTHER;
}
