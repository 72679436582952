import type { Wallet } from 'src/types/types';
import { isAndroid } from '../../utils/isMobile';
import { getWalletConnectConnector } from './wallet-connect';

//@ts-ignore
import ArgentLogo from '../logos/argent_icon.png';
import { type WalletConnectOptions } from './wallet-connect-v2';

export const argent = (options: Omit<WalletConnectOptions, 'chains'>): Wallet => ({
    id: 'argent',
    name: 'Argent',
    iconUrl: ArgentLogo,
    downloadUrls: {
        qrCode: 'https://argent.link/app',
    },
    createConnector: (chains) => {
        const connector = getWalletConnectConnector('argent', options, chains);
        return {
            connector,
            mobile: {
                getAppLinkUri: () => {
                    //https://argent.link/app/wc?uri=${encodeURIComponent(uri)
                    return isAndroid() ? undefined : 'argent://app/wc';
                },
            },
        };
    },
});
