import type { Chain, EVMProvider, Wallet } from '../../types';
import { ConnectorNotFoundError, UserRejectedRequestError } from '../../types';
import { isAndroid } from '../../utils/isMobile';
import { EVMConnector } from './base';

import { isMetaMask } from '../../utils';
import MetaMaskLogo from '../logos/metamask_icon.png';
import { getWalletConnectConnector } from './wallet-connect';
import type { WalletConnectOptions } from './wallet-connect-v2';

export class MetaMaskConnector extends EVMConnector {
    private metamaskProvider?: any;

    #getProvider;

    constructor({ chains, getProvider }: { chains?: Chain[]; getProvider: () => any }) {
        super(chains);
        this.#getProvider = getProvider;
    }

    async connect(): Promise<EVMProvider> {
        const provider = await this.#getProvider();
        if (!provider) throw new ConnectorNotFoundError();
        if (provider.on) {
            provider.removeListener('accountsChanged', this.onAccountsChanged);
            provider.on('accountsChanged', this.onAccountsChanged);
            provider.removeListener('chainChanged', this.onChainChanged);
            provider.on('chainChanged', this.onChainChanged);
            provider.removeListener('disconnect', this.onDisconnect);
            provider.on('disconnect', this.onDisconnect);
        }
        this.emit('message', { type: 'connecting' });
        try {
            const accounts = await provider.request({ method: 'eth_requestAccounts' });
            console.log('metamask eth_requestAccounts result:', accounts);
        } catch (error) {
            console.log('metamask connect error', error);
            if ((error as any).code === 4001) {
                throw new UserRejectedRequestError(error as Error);
            }

            throw error;
        }

        this.provider = provider;
        return provider;
    }

    async disconnect(): Promise<void> {
        this.provider?.removeListener('accountsChanged', this.onAccountsChanged);
        this.provider?.removeListener('chainChanged', this.onChainChanged);
        this.provider?.removeListener('disconnect', this.onDisconnect);
        this.provider = undefined;
    }

    async getProvider() {
        return this.#getProvider();
    }

    private onAccountsChanged = (accounts: string[]) => {
        console.log('metamask accountsChanged', accounts);
        if (accounts.length === 0) {
            this.isSwitchChain = false;
            this.onDisconnect();
        }
        this.emit('accountsChanged', accounts);
    };

    private onChainChanged = (chainId: string) => {
        this.isSwitchChain = true;
        this.emit('chainChanged', chainId);
    };

    private onDisconnect = () => {
        console.log('onDisconnect isSwitchChain', this.isSwitchChain);
        if (this.isSwitchChain || this.isSwitchChain === undefined) {
            this.isSwitchChain = false;
            return;
        }
        console.log('metamask on disconnect');
        this.provider = undefined;
        this.emit('disconnect');
    };
}

export const metaMask = (options: Omit<WalletConnectOptions, 'chains'>): Wallet => {
    const providers = typeof window !== 'undefined' && window.ethereum?.providers;

    const isMetaMaskInjected =
        typeof window !== 'undefined' &&
        typeof window.ethereum !== 'undefined' &&
        (window.ethereum.providers?.some(isMetaMask) || window.ethereum.isMetaMask);
    const getProvider = () =>
        providers ? providers.find(isMetaMask) : typeof window !== 'undefined' ? window.ethereum : undefined;
    console.log('metaMask', providers, getProvider(), isMetaMaskInjected);

    const shouldUseWalletConnect = !isMetaMaskInjected;

    return {
        id: 'metamask',
        name: 'MetaMask',
        iconUrl: MetaMaskLogo,
        downloadUrls: {
            browserExtension: 'https://metamask.io/download',
            qrCode: 'https://metamask.io/download',
        },
        createConnector: (chains) => {
            const evmChains = chains?.filter((chain) => chain.name.toLowerCase() !== 'solana');
            const connector = shouldUseWalletConnect
                ? getWalletConnectConnector('metamask', options, chains)
                : new MetaMaskConnector({
                      chains: evmChains,
                      getProvider,
                  });

            const getAppLinkUri = () => {
                return isAndroid() ? undefined : 'metamask://wc';
            };
            return {
                connector,
                mobile: {
                    getAppLinkUri: shouldUseWalletConnect ? getAppLinkUri : undefined,
                },
            };
        },
    };
};
