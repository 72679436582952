export const PARTICLE_CONNECT_CACHED_PROVIDER = 'particle_connect_cached_provider';
export const WALLETCONNECT_DEEPLINK_CHOICE = 'WALLETCONNECT_DEEPLINK_CHOICE';
export const PARTICLE_CONNECT_CACHED_CHAIN = 'particle_connect_cached_chain';

export function setStorage(key: string, value?: string) {
    if (localStorage) {
        if (value) {
            localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
    }
}

export function getStorage(key: string): string | null {
    if (!localStorage) {
        return null;
    }
    return localStorage.getItem(key);
}
