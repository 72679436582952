import type { Wallet } from 'src/types';
import { isAndroid } from '../../utils';
import OmniLogo from '../logos/omni_icon.png';
import { getWalletConnectConnector } from './wallet-connect';
import type { WalletConnectOptions } from './wallet-connect-v2';

export const omni = (options: Omit<WalletConnectOptions, 'chains'>): Wallet => ({
    id: 'omni',
    name: 'Omni',
    iconUrl: OmniLogo,
    downloadUrls: {
        qrCode: 'https://omniwallet.app.link',
    },
    createConnector: (chains) => {
        const connector = getWalletConnectConnector('omni', options, chains);
        return {
            connector,
            mobile: {
                getAppLinkUri: () => {
                    return isAndroid() ? undefined : 'omni://wc';
                },
            },
        };
    },
});
