import { ActiveAction } from '@particle-network/analytics';
import type { LoginOptions } from '@particle-network/auth';
import { ParticleNetwork } from '@particle-network/auth';
import EventEmitter from 'eventemitter3';
import { ConnectorNotFoundError } from '../types';
import { getChainInfo, getEVMChainById, getSolanaChainById, isChainSupported } from '../types/chains';
import { ConnectController } from './connect-controller';

import type { ParticleConnector } from '../providers/connectors/base';
import { PARTICLE_CONNECT_CACHED_CHAIN, getStorage, setStorage } from '../storage';
import type { Chain, ChainInfo, ConnectConfig, ParticleConnectorMap, Provider, WalletMeta } from '../types';
import { getChainId, getPublicAddress } from '../utils';
import { connectIdToLoginType } from '../utils/active';
import { getVersion } from '../utils/common';

export interface ConnectEvents {
    connect(provider: Provider): void;
    disconnect(): void;
    chainChanged(chain: Chain | undefined): void;
    providerChanged(provider: Provider): void;
    accountsChanged(accounts: string[]): void;
    message(message: any): void;
}

export class ParticleConnect extends EventEmitter<ConnectEvents> {
    private controller: ConnectController;

    private connectorMap: ParticleConnectorMap = {};

    public particle: ParticleNetwork;

    private particleChain?: Chain;

    constructor(private config: ConnectConfig) {
        super();
        this.config.chains.forEach((chain) => {
            if (!isChainSupported(chain)) {
                throw new Error('chain not supported');
            }
        });
        this.controller = new ConnectController(config);
        const { particleWalletEntry, chains, wallets, ...particleConfig } = this.config;
        if (typeof window !== 'undefined') {
            if (!window.particle) {
                const chainJson = getStorage(PARTICLE_CONNECT_CACHED_CHAIN);
                let chain = chains[0];
                if (chainJson) {
                    const localChain = this.config.chains.find((chain) => chain.id === JSON.parse(chainJson).id);
                    if (localChain) {
                        chain = localChain;
                    }
                }
                const particleNetWorkConfig = {
                    ...particleConfig,
                    wallet: particleWalletEntry,
                    chainName: chain?.name,
                    chainId: chain?.id,
                };
                console.log('new ParticleNetwork', particleNetWorkConfig);
                this.particle = new ParticleNetwork(particleNetWorkConfig);
            } else {
                this.particle = window.particle;
            }
        } else {
            this.particle = {} as ParticleNetwork;
        }

        if (wallets && wallets.length > 0) {
            wallets.forEach((wallet) => {
                const particleConnect = wallet.createConnector(this.config.chains);
                this.connectorMap[wallet.id] = particleConnect;
                particleConnect.connector.off('accountsChanged', this.onAccountsChanged);
                particleConnect.connector.on('accountsChanged', this.onAccountsChanged);
                particleConnect.connector.off('chainChanged', this.onChainChanged);
                particleConnect.connector.on('chainChanged', this.onChainChanged);
                particleConnect.connector.off('disconnect', this.onDisconnect);
                particleConnect.connector.on('disconnect', this.onDisconnect);
            });
        }
    }

    get version() {
        return getVersion();
    }

    walletMetas(): WalletMeta[] {
        if (!this.config.wallets) {
            return [];
        }
        return this.config.wallets.map((wallet) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { createConnector, ...info } = wallet;
            const meta: WalletMeta = info;
            return meta;
        });
    }

    public getConnector(id): ParticleConnector | undefined {
        const connector = this.connectorMap[id];
        return connector;
    }

    private onAccountsChanged = (accounts: string[]) => {
        console.log('onAccountsChanged:', accounts);
        if (accounts.length === 0) {
            this.onDisconnect();
        } else {
            this.emit('accountsChanged', accounts);
        }
    };

    private onChainChanged = (chainId: string) => {
        const chain = getEVMChainById(Number(chainId)) as ChainInfo;
        if (chain) {
            // metamask 主动调用时不包含icon 可以排出掉事件重复派发
            if (chain.icon) {
                this.emit('chainChanged', chain);
            }
        } else {
            const solanaChain = getSolanaChainById(Number(chainId));
            if (solanaChain) {
                this.emit('chainChanged', solanaChain);
            } else {
                this.emit('chainChanged', undefined);
            }
        }
    };

    private onDisconnect = () => {
        try {
            this.controller.setCachedProvider();
            setStorage(PARTICLE_CONNECT_CACHED_CHAIN);
            this.emit('disconnect');
        } catch (error) {
            console.error('onDisconnect error');
        }
    };

    private async connectParticle(options?: LoginOptions): Promise<Provider> {
        const id = 'particle';
        if (!this.particle.auth.isLogin()) {
            await this.particle.auth.login(options);
        }

        const chainName = this.particle.auth.config.chainName;
        const chainId = this.particle.auth.config.chainId;
        if (!chainName || !chainId) throw new Error('chain not supported');
        this.particleChain = {
            name: chainName,
            id: chainId,
        };
        const chainInfo = getChainInfo(this.particleChain);
        if (!chainInfo) {
            throw new Error('chain not supported');
        }
        setStorage(PARTICLE_CONNECT_CACHED_CHAIN, JSON.stringify(this.particleChain));
        const provider: Provider = await this.createParticleProvider(chainInfo.chainType);
        const publicAddress = this.particle.auth.wallet()?.public_address;
        if (!publicAddress) {
            throw new Error('wallet create failed');
        }
        this.controller.setCachedProvider(id);
        this.emit('connect', provider);

        return provider;
    }

    async connect(id = 'particle', options?: any): Promise<Provider> {
        try {
            if (id === 'particle') {
                return this.connectParticle(options);
            }

            const connector = this.getConnector(id);
            if (!connector) {
                throw new Error('connector not found, the id is ' + id);
            }
            const provider = await connector.connector.connect();
            this.controller.setCachedProvider(id);
            this.emit('connect', provider);

            if (!options || !options.connectToCachedProvider) {
                const address = await getPublicAddress(provider);
                const chainId = await getChainId(provider);
                this.particle.bi.active({
                    chain_id: chainId,
                    identity: address,
                    login_type: connectIdToLoginType(id),
                    action: ActiveAction.LOGIN,
                    wallet_address: address,
                });
            }

            return provider;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    async connectToCachedProvider(): Promise<Provider | undefined> {
        const providerId = this.cachedProviderId();
        if (providerId) {
            return this.connect(providerId, { connectToCachedProvider: true });
        }
        return undefined;
    }

    async disconnect(options?: any): Promise<void> {
        const providerId = this.cachedProviderId();
        if (!providerId) {
            return;
        }
        if (providerId === 'particle') {
            await this.particle.auth.logout(!!options?.hideLoading);
            this.controller.setCachedProvider();
            setStorage(PARTICLE_CONNECT_CACHED_CHAIN);
            this.emit('disconnect');
        } else {
            const connector = this.getConnector(providerId);
            if (connector) {
                await connector.connector.disconnect();
                this.controller.setCachedProvider();
                setStorage(PARTICLE_CONNECT_CACHED_CHAIN);
                this.emit('disconnect');
            }
        }
    }

    /**
     * switch chain
     * @param chain
     * @returns
     */
    async switchChain(chain: Chain): Promise<void> {
        const chainInfo = getChainInfo(chain);
        if (!chainInfo) {
            throw new Error('chain not supported');
        }
        const id = this.cachedProviderId();
        if (!id) {
            throw new ConnectorNotFoundError();
        }

        if (id === 'particle') {
            await this.particle.switchChain(chain as any, true);
            let provider;
            if (this.particleChain) {
                const oldChainInfo = getChainInfo(this.particleChain);
                if (oldChainInfo && oldChainInfo.chainType !== chainInfo.chainType) {
                    provider = await this.createParticleProvider(chainInfo.chainType);
                }
            }
            this.particleChain = chain;
            setStorage(PARTICLE_CONNECT_CACHED_CHAIN, JSON.stringify(this.particleChain));
            if (provider) {
                this.emit('providerChanged', provider);
            }
            this.emit('chainChanged', chain);
        } else {
            const connector = this.getConnector(id);
            if (connector && (connector.connector as any).switchChain) {
                await (connector.connector as any).switchChain(chain.id);
                this.particleChain = chain;
                // @ts-ignore
                if (chain && chain?.icon) {
                    this.emit('chainChanged', chain);
                }
            } else {
                throw new Error('not supported switch chain');
            }
        }
    }

    cachedProviderId(): string | null {
        return this.controller.getCachedProvider();
    }

    private async createParticleProvider(chainType: string): Promise<Provider> {
        let connector: Provider;
        if (chainType === 'evm') {
            try {
                const { ParticleProvider } = await import('@particle-network/provider');
                connector = new ParticleProvider(this.particle.auth);
            } catch (error: any) {
                console.error(error);
                throw new Error('Import @particle-network/provider failed');
            }
        } else {
            try {
                const { SolanaWallet } = await import('@particle-network/solana-wallet');
                const wallet = new SolanaWallet(this.particle.auth);
                connector = wallet as any;
            } catch (error: any) {
                throw new Error('Import @particle-network/solana-wallet failed');
            }
            // throw new Error('not supported yet');
        }
        return connector;
    }
}
