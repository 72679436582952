import { injected } from '../providers';
import type { EVMProvider, IInjectedProvidersMap, IProviderInfo, Provider, SolanaProvider } from '../types';
// @ts-ignore
import InjectedLogo from '../providers/logos/injected.png';

export async function getPublicAddress(provider: Provider): Promise<string> {
    let account;
    if (isEVMProvider(provider)) {
        console.log('evm get account');
        const accounts = await provider.request({ method: 'eth_accounts' });
        account = accounts[0];
    } else {
        console.log('solana get publicKey');
        account = provider.publicKey?.toBase58();
    }
    return account;
}

export async function getChainId(provider: Provider): Promise<number> {
    let chainId;
    if (isEVMProvider(provider)) {
        const id = await provider.request({ method: 'eth_chainId' });
        chainId = Number(id);
    } else {
        if ((provider as any).isParticleNetwork && typeof window != 'undefined' && window.particle) {
            chainId = window.particle.auth.chainId();
        } else {
            chainId = 101;
        }
    }
    return chainId;
}

export function isEVMProvider(provider: Provider): provider is EVMProvider {
    return typeof (provider as EVMProvider)['publicKey'] === 'undefined';
}

export function isSolanaProvider(provider: Provider): provider is SolanaProvider {
    return typeof (provider as SolanaProvider)['publicKey'] !== 'undefined';
}

export function checkInjectedProviders(): IInjectedProvidersMap {
    if (typeof window === 'undefined') {
        return {
            injectedAvailable: false,
        };
    }

    const result = {
        injectedAvailable: typeof window !== 'undefined' && !!window.ethereum,
    };
    if (result.injectedAvailable) {
        Object.values(injected).forEach((provider) => {
            const isAvailable = verifyInjectedProvider(provider.check);
            if (isAvailable) {
                result[provider.check] = true;
            }
        });
    }

    return result;
}

export function verifyInjectedProvider(check: string): boolean {
    if (typeof window === 'undefined') {
        return false;
    }
    return typeof window !== 'undefined' && window.ethereum && window.ethereum[check];
}

export function getInjectedProvider(): IProviderInfo | undefined {
    let result;
    const injectedProviders = checkInjectedProviders();
    if (injectedProviders.injectedAvailable) {
        const { injectedAvailable, ...providers } = injectedProviders;
        const checks = Object.keys(providers);
        if (checks.length > 0) {
            result = getProviderInfoFromChecksArray(checks);
        } else {
            result = {
                id: 'injected',
                name: 'Injected Wallet',
                logo: InjectedLogo,
                type: 'injected',
                check: '',
            };
        }
    }
    return result;
}

export function getProviderInfoFromChecksArray(checks: string[]): IProviderInfo | undefined {
    return Object.values(injected).find((info) => checks.includes(info.check));
}
