import type { ConnectConfig } from '../types';
import { getStorage, PARTICLE_CONNECT_CACHED_PROVIDER, setStorage } from '../storage';
import type { ParticleConnector } from '../providers/connectors/base';

export class ConnectController {
    private _config: ConnectConfig;

    constructor(config: ConnectConfig) {
        this._config = config;
    }

    public createConnector(id: string): ParticleConnector {
        const wallets = this._config.wallets;
        if (wallets) {
            const wallet = wallets.find((wallet) => wallet.id === id);
            if (wallet) {
                // 修改
                const particleConnector = wallet.createConnector();
                return particleConnector;
            }
        }
        throw new Error('wallet id not found');
    }

    public setCachedProvider(id?: string) {
        setStorage(PARTICLE_CONNECT_CACHED_PROVIDER, id);
    }

    public getCachedProvider(): string | null {
        return getStorage(PARTICLE_CONNECT_CACHED_PROVIDER);
    }
}
