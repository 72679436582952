import type { Chain, Wallet } from '../../types';
import { isIOS } from '../../utils';
import WalletConnectLogo from '../logos/wallet_connect_icon.png';
import type { WalletConnectOptions } from './wallet-connect-v2';
import WalletConnectV2Connector from './wallet-connect-v2';

type SerializedOptions = string;
const sharedConnectors = new Map<SerializedOptions, WalletConnectV2Connector>();

function createConnector(options: WalletConnectOptions) {
    const connector = new WalletConnectV2Connector(options);
    sharedConnectors.set(JSON.stringify(options), connector);
    return connector;
}

export function getWalletConnectConnector(id: string, options: WalletConnectOptions, chains?: Chain[]) {
    let config = isIOS() && id === 'walletconnect_v2' ? { ...options, showQrModal: true } : options;
    const evmChains = chains?.filter((chain) => chain.name.toLowerCase() !== 'solana');
    config = { ...config, chains: evmChains };

    const serializedOptions = JSON.stringify(config);
    const sharedConnector = sharedConnectors.get(serializedOptions);
    return sharedConnector ?? createConnector(config);
}

export function walletconnect(options: Omit<WalletConnectOptions, 'chains'>): Wallet {
    return {
        id: 'walletconnect_v2',
        name: 'WalletConnect',
        iconUrl: WalletConnectLogo,
        createConnector: (chains) => {
            const connector = getWalletConnectConnector('walletconnect_v2', options, chains);
            return {
                connector,
            };
        },
    };
}
