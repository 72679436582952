import { intToHex } from '@particle-network/auth';
import { ParticleDelegateProvider } from '@particle-network/provider';
import EthereumProvider, {
    OPTIONAL_EVENTS,
    OPTIONAL_METHODS,
    REQUIRED_EVENTS,
    REQUIRED_METHODS,
} from '@walletconnect/ethereum-provider';
import type { EthereumProviderOptions } from '@walletconnect/ethereum-provider/dist/types/EthereumProvider';
import {
    SwitchChainError,
    UserRejectedRequestError,
    getChainInfo,
    type Chain,
    type EVMProvider,
    type ProviderRpcError,
} from '../../types';
import { getEVMRpcUrl } from '../../utils';
import { EVMConnector } from './base';

export type WalletConnectOptions = {
    /**
     * WalletConnect Cloud Project ID.
     * @link https://cloud.walletconnect.com/sign-in.
     */
    projectId: EthereumProviderOptions['projectId'];
    /**
     * Metadata for your app.
     * @link https://docs.walletconnect.com/2.0/javascript/providers/ethereum#initialization
     */
    metadata?: EthereumProviderOptions['metadata'];
    /**
     * Whether or not to show the QR code modal.
     * @default true
     * @link https://docs.walletconnect.com/2.0/javascript/providers/ethereum#initialization
     */
    showQrModal?: EthereumProviderOptions['showQrModal'];
    /**
     * Options of QR code modal.
     * @link https://docs.walletconnect.com/2.0/web3modal/options
     */
    qrModalOptions?: EthereumProviderOptions['qrModalOptions'];

    chains?: Chain[];

    /**
     * @default true
     */
    isNewChainsStale?: boolean;
};

const NAMESPACE = 'eip155';
const REQUESTED_CHAINS_KEY = 'wc_requestedChains';
const ADD_ETH_CHAIN_METHOD = 'wallet_addEthereumChain';

export default class WalletConnectV2Connector extends EVMConnector {
    #provider?: EthereumProvider;
    #initProviderPromise?: Promise<void>;
    #chains: Chain[];

    readonly options: WalletConnectOptions;

    constructor(options: WalletConnectOptions) {
        const optionsCompat = { isNewChainsStale: true, showQrModal: true, ...options };
        super(optionsCompat);
        this.options = optionsCompat;
        if (this.options.chains) {
            this.#chains = this.options.chains;
        } else {
            this.#chains = [{ name: 'ethereum', id: 1 }];
        }
        this.#createProvider();
    }

    async connect(): Promise<EVMProvider> {
        try {
            const provider = await this.getProvider();
            this.#setupListeners();
            console.log('walletconnect provider session', provider.session);
            const isChainsStale = this.#isChainsStale();
            console.log('walletconnect connect', isChainsStale, provider.session);
            // If there is an active session with stale chains, disconnect the current session.
            if (provider.session && isChainsStale) await provider.disconnect();

            // If there no active session, or the chains are stale, connect.
            if (!provider.session || isChainsStale) {
                const [defaultChain, ...optionalChains] = this.#chains.map(({ id }) => id);

                this.emit('message', { type: 'connecting' });

                await provider.connect({
                    chains: [defaultChain],
                    optionalChains,
                });

                this.#setRequestedChainsIds(this.#chains.map(({ id }) => id));
            }

            // If session exists and chains are authorized, enable provider for required chain
            await provider.enable();
            const particleProvider = new ParticleDelegateProvider(window.particle!.auth, provider);
            this.provider = particleProvider;
            return this.provider;
        } catch (error) {
            console.error('walletconnect v2 connect error', error);
            if (/user rejected/i.test((error as ProviderRpcError)?.message)) {
                throw new UserRejectedRequestError(error as Error);
            }
            throw error;
        }
    }

    async disconnect() {
        const provider = await this.getProvider();
        try {
            await provider.disconnect();
        } catch (error) {
            if (!/No matching key/i.test((error as Error).message)) throw error;
        } finally {
            this.#removeListeners();
            this.#setRequestedChainsIds([]);
        }
        this.provider = undefined;
    }

    switchChain = async (chainId: number): Promise<void> => {
        const chain = this.#chains.find((chain) => chain.id === chainId);
        if (!chain) throw new SwitchChainError('chain not found on connector.');
        const chainInfo = getChainInfo(chain);
        if (!chainInfo) {
            throw new Error('chain not supported');
        }

        try {
            const provider = await this.getProvider();
            const namespaceChains = this.#getNamespaceChainsIds();
            const namespaceMethods = this.#getNamespaceMethods();
            const isChainApproved = namespaceChains.includes(chainId);

            if (!isChainApproved && namespaceMethods.includes(ADD_ETH_CHAIN_METHOD)) {
                const params = {
                    chainId: intToHex(chainInfo.id),
                    chainName: chainInfo.fullname,
                    nativeCurrency: chainInfo.nativeCurrency,
                    rpcUrls: [getEVMRpcUrl(chainInfo.id)],
                    blockExplorerUrls: chainInfo.blockExplorerUrls,
                };
                if (chainInfo.blockExplorerUrls.length === 0) {
                    // @ts-ignore
                    delete params.blockExplorerUrls;
                }
                console.log(ADD_ETH_CHAIN_METHOD, params);
                await provider.request({
                    method: ADD_ETH_CHAIN_METHOD,
                    params: [params],
                });
                const requestedChains = this.#getRequestedChainsIds();
                requestedChains.push(chainId);
                this.#setRequestedChainsIds(requestedChains);
            }
            console.log('wallet_switchEthereumChain', chainId);
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: intToHex(chainId) }],
            });
        } catch (error) {
            console.error('walletconnect v2 switchChain', error);
            const message = typeof error === 'string' ? error : (error as ProviderRpcError)?.message;
            if (/user rejected request/i.test(message)) {
                throw new UserRejectedRequestError(error as Error);
            }
            throw new SwitchChainError(error as Error);
        }
    };

    async getProvider(): Promise<EthereumProvider> {
        if (!this.#provider) await this.#createProvider();
        return this.#provider!;
    }

    async #createProvider() {
        if (!this.#initProviderPromise && typeof window !== 'undefined') {
            this.#initProviderPromise = this.#initProvider();
        }
        return this.#initProviderPromise;
    }

    async #initProvider() {
        const { projectId, showQrModal, qrModalOptions, metadata } = this.options;
        const [defaultChain, ...optionalChains] = this.#chains.map(({ id }) => id);
        const rpcMap = {};
        this.#chains.forEach(({ id }) => {
            rpcMap[id.toString()] = getEVMRpcUrl(id);
        });
        this.#provider = await EthereumProvider.init({
            projectId,
            chains: [defaultChain],
            optionalChains: optionalChains,
            methods: REQUIRED_METHODS,
            optionalMethods: OPTIONAL_METHODS,
            events: REQUIRED_EVENTS,
            optionalEvents: OPTIONAL_EVENTS,
            rpcMap,
            metadata,
            showQrModal: showQrModal ?? true,
            qrModalOptions: {
                ...qrModalOptions,
                chainImages: qrModalOptions?.chainImages,
                themeVariables: {
                    ...qrModalOptions?.themeVariables,
                    '--w3m-z-index': '3000',
                },
            },
        });
    }

    #setupListeners() {
        if (!this.#provider) return;
        this.#removeListeners();
        this.#provider.on('accountsChanged', this.onAccountsChanged);
        this.#provider.on('chainChanged', this.onChainChanged);
        this.#provider.on('disconnect', this.onDisconnect);
        this.#provider.on('session_delete', this.onDisconnect);
        this.#provider.on('display_uri', this.onDisplayUri);
    }

    #removeListeners() {
        if (!this.#provider) return;
        this.#provider.removeListener('accountsChanged', this.onAccountsChanged);
        this.#provider.removeListener('chainChanged', this.onChainChanged);
        this.#provider.removeListener('disconnect', this.onDisconnect);
        this.#provider.removeListener('session_delete', this.onDisconnect);
        this.#provider.removeListener('display_uri', this.onDisplayUri);
    }

    #isChainsStale() {
        const namespaceMethods = this.#getNamespaceMethods();
        if (namespaceMethods.includes(ADD_ETH_CHAIN_METHOD)) return false;
        if (!this.options.isNewChainsStale) return false;

        const requestedChains = this.#getRequestedChainsIds();
        const connectorChains = this.#chains.map(({ id }) => id);
        const namespaceChains = this.#getNamespaceChainsIds();

        if (namespaceChains.length && !namespaceChains.some((id) => connectorChains.includes(id))) return false;

        return !connectorChains.every((id) => requestedChains.includes(id));
    }

    #setRequestedChainsIds(chains: number[]) {
        localStorage.setItem(REQUESTED_CHAINS_KEY, JSON.stringify(chains));
    }

    #getRequestedChainsIds(): number[] {
        return JSON.parse(localStorage.getItem(REQUESTED_CHAINS_KEY) ?? '[]');
    }

    #getNamespaceChainsIds() {
        if (!this.#provider) return [];
        const chainIds = this.#provider.session?.namespaces[NAMESPACE]?.chains?.map((chain) =>
            parseInt(chain.split(':')[1] || '')
        );
        return chainIds ?? [];
    }

    #getNamespaceMethods() {
        if (!this.#provider) return [];
        const methods = this.#provider.session?.namespaces[NAMESPACE]?.methods;
        return methods ?? [];
    }

    private onAccountsChanged = (accounts: string[]) => {
        if (accounts.length === 0) {
            this.provider = undefined;
        }
        this.emit('accountsChanged', accounts);
    };

    private onChainChanged = (chainId: string) => {
        this.emit('chainChanged', chainId);
    };

    private onDisconnect = () => {
        if (this.provider) {
            this.provider = undefined;
            this.emit('disconnect');
        }
    };

    private onDisplayUri = (uri: string) => {
        console.log('🚀 ~ file: wallet-connect-v2.ts:275 ~ WalletConnectV2Connector ~ uri:', uri);
        //wc:a4f53c62bea235869099b52919bfe7d02a21ce3f011b59b29e41d821b55b0116@2?relay-protocol=irn&symKey=752d3e182219522f61842c7027fa8ff94e743c53bfba38b82c74bd36451f658b
        if (!this.options.showQrModal) {
            this.emit('message', { type: 'display_uri', data: uri });
        }
    };
}
